// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-articles-conjugation-mdx": () => import("./../../../src/content/articles/conjugation.mdx" /* webpackChunkName: "component---src-content-articles-conjugation-mdx" */),
  "component---src-content-articles-diacritics-mdx": () => import("./../../../src/content/articles/diacritics.mdx" /* webpackChunkName: "component---src-content-articles-diacritics-mdx" */),
  "component---src-content-articles-etymology-mdx": () => import("./../../../src/content/articles/etymology.mdx" /* webpackChunkName: "component---src-content-articles-etymology-mdx" */),
  "component---src-content-articles-ipa-mdx": () => import("./../../../src/content/articles/ipa.mdx" /* webpackChunkName: "component---src-content-articles-ipa-mdx" */),
  "component---src-content-articles-language-reconstruction-revitalization-mdx": () => import("./../../../src/content/articles/language-reconstruction-revitalization.mdx" /* webpackChunkName: "component---src-content-articles-language-reconstruction-revitalization-mdx" */),
  "component---src-content-articles-linguistics-mdx": () => import("./../../../src/content/articles/linguistics.mdx" /* webpackChunkName: "component---src-content-articles-linguistics-mdx" */),
  "component---src-content-resources-lessons-01-introduction-mdx": () => import("./../../../src/content/resources/lessons/01-introduction.mdx" /* webpackChunkName: "component---src-content-resources-lessons-01-introduction-mdx" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-about-corey-mdx": () => import("./../../../src/pages/about/corey.mdx" /* webpackChunkName: "component---src-pages-about-corey-mdx" */),
  "component---src-pages-about-tribes-mdx": () => import("./../../../src/pages/about/tribes.mdx" /* webpackChunkName: "component---src-pages-about-tribes-mdx" */),
  "component---src-pages-about-yesanechi-mdx": () => import("./../../../src/pages/about/yesanechi.mdx" /* webpackChunkName: "component---src-pages-about-yesanechi-mdx" */),
  "component---src-pages-articles-index-mdx": () => import("./../../../src/pages/articles/index.mdx" /* webpackChunkName: "component---src-pages-articles-index-mdx" */),
  "component---src-pages-bibliography-mdx": () => import("./../../../src/pages/bibliography.mdx" /* webpackChunkName: "component---src-pages-bibliography-mdx" */),
  "component---src-pages-contribute-donate-mdx": () => import("./../../../src/pages/contribute/donate.mdx" /* webpackChunkName: "component---src-pages-contribute-donate-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-other-resources-mdx": () => import("./../../../src/pages/other-resources.mdx" /* webpackChunkName: "component---src-pages-other-resources-mdx" */),
  "component---src-pages-resources-lessons-mdx": () => import("./../../../src/pages/resources/lessons.mdx" /* webpackChunkName: "component---src-pages-resources-lessons-mdx" */),
  "component---src-pages-resources-orthography-mdx": () => import("./../../../src/pages/resources/orthography.mdx" /* webpackChunkName: "component---src-pages-resources-orthography-mdx" */),
  "component---src-pages-resources-phonemes-mdx": () => import("./../../../src/pages/resources/phonemes.mdx" /* webpackChunkName: "component---src-pages-resources-phonemes-mdx" */),
  "component---src-pages-resources-phonology-mdx": () => import("./../../../src/pages/resources/phonology.mdx" /* webpackChunkName: "component---src-pages-resources-phonology-mdx" */)
}

